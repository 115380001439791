import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import $ from 'jquery';
import { toast } from 'react-toastify'
import axiosInstance from "../../config/axiosInterceptor"
const AddConversion = () => {

  const navigate = useNavigate();

  const subbbb = async(e) => {
    e.preventDefault();

    var a = $("input[name='coins']").val();
    var b = $("input[name='recCoins']").val();
    let text = "This field cannot be empty";

    if (!a) {
      $('.form-error1').html(text);
    }
    else if (!b) {
      $('.form-error2').html(text);
    }

    
    else if (a != '' && b != '' ) {
      let formdata = {
        coins: a,
        recCoins: b,
      };
      let res = await axiosInstance.post(`${process.env.REACT_APP_BASEURL5}/admin/commission/addConversion`,formdata)
      // fetch('https://mastishortss.com/video/admin/commission/addCommission', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify(formdata)
      // })
      // .then(response => response.json())
      // .then(res => {
        if (res.data.success == 1) {
          toast.success(res.data.message);
          setTimeout(() => {
            navigate('/admin/viewconversion');
          }, 1000);
        } else {
          toast.error(res.data.message);
        }
      
      
    }
  }


  return (
    <>
      <section className="content-header">
        <h1 style={{ marginBottom: '20px' }}>
          Add Conversion
        </h1>
        <ol className="breadcrumb" style={{ marginBottom: '20px' }}>
          <li><Link to="/admin"><i className="fa fa-dashboard"></i> Home</Link></li>
          <li><Link to="/admin/viewconversion">Conversion</Link></li>
          <li className="active">Add Conversion</li>
        </ol>
      </section>
      <section className="content" style ={{ padding: '0 15px' }}>
        <div className="row">
          <div className="col-md-12">
            <form role="form">
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Add</h3>
                </div>
                <div className="box-body">
                  <div className="form-group" style={{ marginBottom: '20px' }}>
                    <label style={{ marginBottom: '10px' }}>Stars</label>
                    <input type="number" className="form-control" name="coins" placeholder="" style={{ width: '100%' }} onChange={() => {
                      $('.form-error1').html('');
                    }} />
                    <div className="form-error1 text-danger"></div>
                  </div>
                  <div className="form-group" style={{ marginBottom: '20px' }}>
                    <label style={{ marginBottom: '10px' }}>M-Coins</label>
                    <input type="number" className="form-control" name="recCoins" placeholder="" style={{ width: '100%' }} onChange={() => {
                      $('.form-error2').html('');
                    }} /> 
                    <div className="form-error2 text-danger"></div>
                  </div>
                  <div className="form-group" style={{ marginBottom: '20px' }}>
                    <input type="submit" className="btn btn-success pull-right" name="submit" defaultValue="Submit" onClick={subbbb}/>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}

export default AddConversion