import React, { useState, useEffect } from 'react';
import axios from 'axios';

const FamilyPkRuleForm = ({ existingFamilyPkRule }) => {
  const [title, setTitle] = useState('');
  const [mainImage, setMainImage] = useState(null);
  const [points, setPoints] = useState([{ point: '', desc: '', image: null }]);

  useEffect(() => {
    // If we're editing an existing FamilyPkRule, populate the form fields
    if (existingFamilyPkRule) {
      setTitle(existingFamilyPkRule.title);
      setPoints(existingFamilyPkRule.points.map(point => ({
        point: point.point,
        desc: point.desc,
        image: null,  // Image paths will be handled by the backend
      })));
    }
  }, [existingFamilyPkRule]);

  const handleMainImageChange = (e) => {
    setMainImage(e.target.files[0]);
  };

  const handlePointImageChange = (index, e) => {
    const updatedPoints = [...points];
    updatedPoints[index].image = e.target.files[0];
    setPoints(updatedPoints);
  };

  const handleInputChange = (index, e) => {
    const updatedPoints = [...points];
    updatedPoints[index][e.target.name] = e.target.value;
    setPoints(updatedPoints);
  };

  const handleAddPoint = () => {
    setPoints([...points, { point: '', desc: '', image: null }]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('image', mainImage);

    points.forEach((point, index) => {
      formData.append(`points[${index}][point]`, point.point);
      formData.append(`points[${index}][desc]`, point.desc);
      if (point.image) formData.append('pointImages', point.image);
    });

    try {
      let response;
      if (existingFamilyPkRule) {
        // If editing, send PUT request
        response = await axios.put(`/api/familyPkRules/${existingFamilyPkRule._id}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      } else {
        // If creating, send POST request
        response = await axios.post(`${process.env.REACT_APP_BASEURL5}/admin/familypk/familyPkRules`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      }
      alert(response.data.message);
    } catch (error) {
      alert('Error: ' + error.response.data.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Title</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter title"
        />
      </div>

      <div>
        <label>Main Image</label>
        <input
          type="file"
          onChange={handleMainImageChange}
        />
      </div>

      {points.map((point, index) => (
        <div key={index}>
          <label>Point {index + 1}</label>
          <input
            type="text"
            name="point"
            value={point.point}
            onChange={(e) => handleInputChange(index, e)}
            placeholder="Enter point number"
          />
          <input
            type="text"
            name="desc"
            value={point.desc}
            onChange={(e) => handleInputChange(index, e)}
            placeholder="Enter point description"
          />
          <input
            type="file"
            onChange={(e) => handlePointImageChange(index, e)}
          />
        </div>
      ))}

      <button type="button" onClick={handleAddPoint}>
        Add Point
      </button>

      <button type="submit">
        {existingFamilyPkRule ? 'Update Family PK Rule' : 'Create Family PK Rule'}
      </button>
    </form>
  );
};

export default FamilyPkRuleForm;
