import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import _ from "lodash";
import { saveAs } from "file-saver";
import Modal from "react-modal";
import PopModal from "../PopModal";
import axiosInstance from "../../config/axiosInterceptor";
Modal.setAppElement("#root");

const User = () => {
  const [isLoading, setIsLoading] = useState(false);

  const baseUrl = process.env.REACT_APP_BASEURL1;
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10); // Default entries per page
  const [search, setSearch] = useState("");
  const [user, setUser] = useState([]);
  const [count, setCount] = useState(0);
  const [sortType, setSortType] = useState("createdAt"); // Default sort type
  const [searchValue, setSearchValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    number: "",
    password: "",
  });

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleChangeInput = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };
  const handleCreateUser = async (valueD) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASEURL5}/admin/user`,
      valueD
    );
    if (data.success == 1) {
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }
  };
  const handleInputSubmit = (e) => {
    e.preventDefault();
    handleCreateUser(userData);
  };
  // Fetch users from API
  const getUsers = async (page, limit, sort) => {
    setUser([]);
    try {
      const response = await axiosInstance.get(
        `/admin/users?page=${page}&limit=${limit}&sortType=${sort}`
      );
      setUser(response.data.users);
      setCount(response.data.count);
    } catch (error) {
      toast.error(error.message);
    }
  };

  // Fetch initial users on component mount
  useEffect(() => {
    getUsers(currentPage, entriesPerPage, sortType);
  }, [currentPage, entriesPerPage, sortType]);

  // Handle pagination click
  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Search function
  const agencyadminlistsearch = async (searchValue) => {
    setUser([]);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL3}/admin/search?search=${searchValue}`
      );
      setUser(response.data.details); // Update user state with search results
    } catch (error) {
      toast.error(error.message);
    }
  };

  // Handle search input change with debounce
  const handleSearchChange = useCallback(
    _.debounce((value) => {
      // setSearch(value);
      if (value !== "") agencyadminlistsearch(value);
      else getUsers(currentPage, entriesPerPage, sortType);
    }, 0),
    [currentPage, entriesPerPage, sortType]
  );

  // Handle sort change
  const handleSortChange = (e) => {
    setSortType(e.target.value);
    setCurrentPage(1); // Reset to first page when sort changes
  };

  // Render pagination numbers
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(
        user.map((item) => ({
          _id: item._id,
          username: item.username,
          name: item.name,
          number: item.number,
          coin: item.coin,
          recievingCoins: item?.recievingCoins,
        }))
      );
    }
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (item) => {
    const isSelected = selectedItems.some(
      (selected) => selected._id === item._id
    );
    if (isSelected) {
      setSelectedItems(
        selectedItems.filter((selected) => selected._id !== item._id)
      );
    } else {
      setSelectedItems([
        ...selectedItems,
        {
          _id: item._id,
          username: item.username,
          name: item.name,
          number: item.number,
          coin: item.coin,
          recievingCoins: item?.recievingCoins,
        },
      ]);
    }
  };
  const exportToCsv = () => {
    try {
      const csvContent = selectedItems.map((item) => ({
        username: item.username,
        name: item.name,
        number: item.number,
        coin: item.coin,
        recievingCoins: item?.recievingCoins,
      }));

      const csvRows = [
        ["Username", "Name", "Number", "Coin", "RecievingCoins"],
        ...csvContent.map((item) => Object.values(item)),
      ];

      const csvString = csvRows.map((row) => row.join(",")).join("\n");
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
      saveAs(blob, "exported_data.csv");
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  return (
    <>
      {isOpen && (
        <div
          className="modal show d-block"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
          style={{ zIndex: 1050 }}
        >
          <div className="modal-dialog" style={{ marginTop: "15rem" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title" id="staticBackdropLabel">
                  Add User
                </h2>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <form className="modal-body " onSubmit={handleInputSubmit}>
                <>
                  <div
                    className="form-floating mt-5"
                    style={{ marginBottom: "2rem" }}
                  >
                    <label htmlFor="floatingInput">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="floatingInput"
                      placeholder="email"
                      name="email"
                      onChange={handleChangeInput}
                    />
                  </div>
                  <div
                    className="form-floating"
                    style={{ marginBottom: "2rem" }}
                  >
                    <label htmlFor="username">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      placeholder="name"
                      name="name"
                      onChange={handleChangeInput}
                    />
                  </div>
                  <div
                    className="form-floating"
                    style={{ marginBottom: "2rem" }}
                  >
                    <label htmlFor="number">Number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="number"
                      placeholder="number"
                      name="number"
                      onChange={handleChangeInput}
                    />
                  </div>
                  <div
                    className="form-floating"
                    style={{ marginBottom: "2rem" }}
                  >
                    <label htmlFor="floatingPassword">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="floatingPassword"
                      placeholder="Password"
                      name="password"
                      onChange={handleChangeInput}
                    />
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="btn btn-success"
                      style={{ fontSize: "1.8rem" }}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      onClick={closeModal}
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                      style={{ fontSize: "1.8rem", marginLeft: "2rem" }}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              </form>
            </div>
          </div>
        </div>
      )}

      <section className="content-header">
        <style jsx>{`
          .rotating-logo {
            width: 100px;
            height: 100px;
            animation: spin 2s linear infinite;
          }

          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}</style>
        <Modal
          isOpen={isLoading}
          contentLabel="Loading Modal"
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center", // Center the content
            },
          }}
        >
          <img
            src="/mastiLive_logo.png"
            alt="Loading..."
            className="rotating-logo"
          />
          <h1>Loading...</h1>
        </Modal>
        <h1>Manage Users</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Users</li>
        </ol>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="col-md-2" style={{ marginTop: "2rem" }}>
                <button
                  className="btn btn-info"
                  style={{ outline: "none" }}
                  onClick={toggleSelectAll}
                >
                  {selectAll ? "Deselect All" : "Select All"}
                </button>
              </div>
              <div style={{ marginTop: "2rem" }}>
                <button
                  type="submit"
                  className="btn btn-info"
                  onClick={exportToCsv}
                  style={{ marginRight: "1rem" }}
                >
                  Export Data
                </button>

                <button
                  type="button"
                  onClick={openModal}
                  className="btn btn-success"
                >
                  Add User
                </button>
              </div>

              <div>
                <div
                  className="col-md-12"
                  style={{ marginTop: "10px", marginBottom: "20px" }}
                >
                  <div className="row" style={{ padding: "16px 0px" }}>
                    <div className="entries-per-page col-md-4">
                      <label htmlFor="entries">Entries per page:</label>
                      <select
                        id="entries"
                        value={entriesPerPage}
                        onChange={(e) =>
                          setEntriesPerPage(parseInt(e.target.value))
                        }
                      >
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                        <option value="5000">5000</option>
                      </select>
                    </div>

                    <div
                      className="col-md-4"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "1rem",
                      }}
                    >
                      <div
                        className="main-data-single-field"
                        style={{ width: "fit-content" }}
                      >
                        <span
                          style={{ fontWeight: "bold", marginRight: "8px" }}
                        >
                          Search
                        </span>
                        <input
                          type="text"
                          id="pname"
                          name="pname"
                          style={{
                            borderRadius: "4px",
                            borderStyle: "groove",
                            width: "150px",
                          }}
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder="Search User"
                        />
                      </div>
                      <button
                        style={{ paddingLeft: ".5rem", paddingRight: ".5rem" }}
                        onClick={() => {
                          handleSearchChange(search);
                          // console.log(search);
                        }}
                      >
                        Go
                      </button>
                    </div>

                    <div
                      className="sort-dropdown col-md-4"
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <label htmlFor="sort">Sort by:</label>
                      <select
                        id="sort"
                        value={sortType}
                        onChange={handleSortChange}
                      >
                        <option value="createdAt">Created At</option>
                        <option value="coin">Coins</option>
                        <option value="recievingCoins">Receiving Coins</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-body">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          onChange={toggleSelectAll}
                          checked={selectAll}
                        />
                      </th>
                      <th>Sr.</th>
                      <th>Image</th>
                      <th>Username</th>
                      {/* <th>Name</th> */}
                      <th>Phone</th>
                      <th>Coins</th>
                      <th>Receiving Coins</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {user.length === 0 ? (
                      <>Loading...</>
                    ) : (
                      user.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedItems.some(
                                (selected) => selected._id === item._id
                              )}
                              onChange={() => handleCheckboxChange(item)}
                            />
                          </td>
                          <td>
                            {entriesPerPage * (currentPage - 1) + index + 1}
                          </td>
                          <td>
                            <img
                              src={`${baseUrl}${item.picture}`}
                              width="30px"
                              height="30px"
                              style={{ borderRadius: "50%" }}
                              alt="user"
                            />
                          </td>
                          <td>{item.username}</td>
                          {/* <td>{item.name}</td> */}
                          <td>{item.number}</td>
                          <td>
                            <span className="label label-warning">
                              {item.coin}
                            </span>
                          </td>
                          <td>
                            <span className="label label-success">
                              {item.recievingCoins}
                            </span>
                          </td>
                          <td>
                            {item.active ? (
                              <span className="label label-success">
                                Active
                              </span>
                            ) : (
                              <span className="label label-danger">
                                Inactive
                              </span>
                            )}
                          </td>
                          <td>
                            <ul className="admin-action btn btn-default">
                              <li className="dropdown">
                                <a
                                  className="dropdown-toggle"
                                  style={{ color: "black" }}
                                  data-toggle="dropdown"
                                  href="#"
                                  aria-expanded="false"
                                >
                                  Action <span className="caret" />
                                </a>
                                <ul className="dropdown-menu dropdown-menu-right">
                                  <li role="presentation">
                                    <Link
                                      role="menuitem"
                                      tabIndex={-1}
                                      to={`/admin/ViewUser?id=${item._id}`}
                                    >
                                      View
                                    </Link>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>

                {/* Pagination */}
                <div className="row">
                  <div className="col-md-5">
                    <ul className="pagination">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          onClick={() => handlePagination(currentPage - 1)}
                          className="page-link"
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                      </li>
                      <li
                        className={`page-item ${
                          currentPage === Math.ceil(count / entriesPerPage)
                            ? "disabled"
                            : ""
                        }`}
                      >
                        <button
                          onClick={() => handlePagination(currentPage + 1)}
                          className="page-link"
                          disabled={
                            currentPage === Math.ceil(count / entriesPerPage)
                          }
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-5">
                    <div
                      className="total-entries"
                      style={{ float: "right", marginTop: "20px" }}
                    >
                      Total entries: <b>{count}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default User;
