import React, { useState, useEffect } from "react";
import axios from "axios";
import axiosInstance from '../../config/axiosInterceptor'
// import "react-datepicker/dist/react-datepicker.css";

const PKBattleComponent = () => {
  const [rounds, setRounds] = useState([]);
  const [activeRound, setActiveRound] = useState(1);
  const [rankings, setRankings] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenSettle, setModalOpenSettle] = useState(false);
  const [matchModalOpen, setMatchModalOpen] = useState(false);
  const [matchSendStars, setMatchSendStars] = useState(false);
  const [transList, setTransList] = useState([]);
  const [modaltransList, setmodalTransList] = useState(false);
  const [matchType, setMatchType] = useState("withdrawal");
  const [matchDateTime, setMatchDateTime] = useState("");
  const [month, setMonth] = useState(new Date().getMonth());
  const [year, setYear] = useState(new Date().getFullYear());
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10); // Adjust limit as needed
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const [newRoundData, setNewRoundData] = useState({
    roundName: "",
    familyPoint: "",
    maxMembers: "",
    startDate: "",
    endDate: "",
    image: null,
  });
  const [newSettle, setNewSettle] = useState({
    points: 0,
    winner: "0",
    id: "",
    familyPkId: ""
  });

  useEffect(() => {
    fetchRounds();
  }, [month, year]);

  const fetchRounds = async () => {
    try {
      setLoading(true)
      const response = await axiosInstance.get(`${process.env.REACT_APP_BASEURL5}/admin/familypk/getRounds?month=${month}&year=${year}`);
      setRounds(response.data.data || []);
      if (response?.data?.data?.length > 0)
        await fetchRankings(rounds[0]?._id);
      setLoading(false)
    } catch (error) {
      console.error("Error fetching rounds:", error);
    }
  };
  const fetchTransList = async () => {
    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_BASEURL5}/admin/familypk/getSendStarHistory?page=${page}&limit=${500}&familyPkId=${activeRound?._id}`);
      setTransList(response.data.history || []);
    } catch (error) {
      console.error("Error fetching rounds:", error);
    }
  };
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleLimitChange = (event) => {
    setLimit(Number(event.target.value));
    setPage(1); // Reset to the first page when changing the limit
  };
  useEffect(() => {
    if (activeRound) {
      fetchRankings(activeRound._id);
    }
  }, [page, limit, activeRound]);
  const fetchRankings = async (round) => {
    try {
      setLoading(true)
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_BASEURL5}/admin/familypk/getRegisteredPks?familyPkId=${round}&page=${page}&limit=${limit}&month=${month}&year=${year}`
      );
      setRankings(response.data.data || []);
      setTotalPages(response.data.totalPages || []);
      setTotalCount(response.data.totalCount || []);
      setLoading(false)
    } catch (error) {
      console.error("Error fetching rankings:", error);
    }
  };

  const handleAddRound = async () => {
    try {
      const formData = new FormData();
      Object.keys(newRoundData).forEach((key) => {
        formData.append(key, newRoundData[key]);
      });

      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BASEURL5}/admin/familypk/createPkRound`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      alert(response.data.message);
      setModalOpen(false);
      fetchRounds();
    } catch (error) {
      console.error("Error creating round:", error);
    }
  };
  const handleSettle = async () => {
    try {
      // const formData = new FormData();
      // Object.keys(newSettle).forEach((key) => {
      //   formData.append(key, newSettle[key]);
      // });
      let conf = window.confirm(
        "Are you sure you want to settle manually. This will reflect in app"
      );
      if (conf == false) {
        return;
      }
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BASEURL5}/admin/familypk/settleFamilyPk`,
        newSettle,
        // { headers: { "Content-Type": "multipart/form-data" } }
      );
      alert(response.data.message);
      setModalOpenSettle(false);
      fetchRounds();
    } catch (error) {
      console.error("Error creating round:", error);
    }
  };
  const handleMatchUsers = async () => {
    try {
      if (new Date(matchDateTime) <= new Date()) {
        alert("Please select a future date and time.");
        return;
      }

      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BASEURL5}/admin/familypk/matchUserAlgo`,
        { familyPkId: activeRound?._id, matchType, matchDateTime }
      );
      alert(response.data.message);
      setMatchModalOpen(false);
    } catch (error) {
      console.error("Error matching users:", error);
    }
  };
  const handleSendStars = async (amount) => {
    try {
      let conf = window.confirm(
        "Are you sure you want to send stars. Your entered amount is " + amount
      );
      if (conf == false) {
        return;
      }
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BASEURL5}/admin/familypk/sendStars`,
        { familyPkId: activeRound?._id, amount }
      );
      alert(response.data.message);
      setMatchSendStars(false);
    } catch (error) {
      console.error("Error matching users:", error);
    }
  };
  const handleDeductStars = async (id) => {
    try {
      let conf = window.confirm(
        "Are you sure you want to deduct stars"
      );
      if (conf == false) {
        return;
      }
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BASEURL5}/admin/familypk/returnStars`,
        { id: id }
      );
      alert(response.data.message);
    } catch (error) {
      console.error("Error matching users:", error);
    }
  };
  const approveRej = async (type) => {
    try {
      let conf = window.confirm(
        "Are you sure you want to " + (type == "1" ? "approve " : "reject ") + "Matched users"
      );
      if (conf === true) {
        if (type == "1") {
          let conf2 = window.confirm("This will start event on app");
          if (conf2 === false) return;
        }
      } else {
        return;
      }

      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BASEURL5}/admin/familypk/approveRej`,
        { familyPkId: activeRound?._id, type: type }
      );
      alert(response.data.message);
    } catch (error) {
      console.error("Error matching users:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewRoundData({ ...newRoundData, [name]: value });
  };
  const handleInputChangeSettle = (e) => {
    const { name, value } = e.target;
    setNewSettle({ ...newSettle, [name]: value });
  };

  const handleFileChange = (e) => {
    setNewRoundData({ ...newRoundData, image: e.target.files[0] });
  };
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      const allFamilyIds = rankings.map(item => item.familyId);
      setSelectedItems(allFamilyIds);
    }
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (item) => {
    const isSelected = selectedItems.some(selected => selected === item);
    if (isSelected) {
      setSelectedItems(selectedItems.filter(selected => selected !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };
  const handleSelectedAction = async () => {
    if (selectedItems.length === 0) {
      alert("Please select at least one row.");
      return;
    }

    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BASEURL5}/admin/familypk/egligibleSet`,
        { egligibleIds: selectedItems, familyPkId: activeRound._id }
      );
      alert(response.data.message);
      setSelectedItems([]); // Clear selected rows
    } catch (error) {
      console.error("Error performing action on selected rows:", error);
    }
  };

  const [isEditing, setIsEditing] = useState("");
  const [selectedDateTime, setSelectedDateTime] = useState();

  const handleEditClick = (id) => {
    setIsEditing(id);
  };

  const handleDateChange = (event) => {
    setSelectedDateTime(event.target.value);
  };

  const handleSubmit = async (id) => {
    setIsEditing(false);
  
    const date = new Date(selectedDateTime);
    const formattedDateTime = `${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}/${date.getFullYear()} ${date
      .getHours()
      .toString()
      .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
  
    await updateDateTime(isEditing, formattedDateTime);
  };
  const updateDateTime = async (id,dateTime) => {
    let conf = window.confirm(
      "Are you sure you want to update date "
    );
    if(conf===false) return;
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BASEURL5}/admin/familypk/updateDate`,
        { id,dateTime }
      );
      alert(response.data.message);
      // setSelectedItems([]); // Clear selected rows
    } catch (error) {
      console.error("Error performing action on selected rows:", error);
    }
  };
  return (
    <div className="container py-4 ">
      {/* Month and Year Dropdown */}
      <section className="content">
        <div
          className="row"
          style={{ display: "flex", justifyContent: "between" }}
        >
          <div className="col-md-10 col-12">
            <div className="box box-warning">
              {/* Event Images Section */}
              <div
                className="box-header with-border"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div style={{ display: "flex", gap: "40px" }}>
                  <div>
                    <img
                      src={process.env.REACT_APP_BASEURL + activeRound.image}
                      alt="Event"
                      style={{
                        width: "400px",
                        height: "200px",
                        borderRadius: "2%",
                      }}
                    />
                  </div>
                </div>

                {/* Event Details Section */}
                <div style={{ marginLeft: "40px" }}>
                  <h3>Round Name: {activeRound?.roundName}</h3>
                  <h4>Round Code: {activeRound?._id}</h4>
                  <h4>Family Points: {activeRound?.familyPoint}</h4>
                  <h4>Max Members: {activeRound?.maxMembers}</h4>
                  <p>
                    Reg Time: {activeRound.startDate} to {activeRound.endDate}
                  </p>
                  <button
                    className={`btn ${activeRound.egligibleSet === "1"
                      ? "btn-success"
                      : "btn-danger"
                      }`}
                  >
                    {activeRound.egligibleSet === "1"
                      ? "Eligible Set"
                      : "Not-Eligible Set"}
                  </button>
                  <button
                    className={`btn ${activeRound.live === "1"
                      ? "btn-success"
                      : "btn-danger"
                      }`}
                  >
                    {activeRound.live === "1"
                      ? "AppLive"
                      : "Not-AppLive"}
                  </button>
                  <button style={{ margin: "0 100%" }} className="btn btn-success" onClick={() => setMatchSendStars(true)}>
                    Send Stars
                  </button>
                  <button style={{ margin: "0 100%" }} className="btn btn-warning" onClick={() => { setmodalTransList(true); fetchTransList() }}>
                    Transaction List
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Month and Year Dropdown */}
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div style={{ marginBottom: "20px", marginTop: "20px" }}>
          <select style={{ padding: "8px", marginLeft: "8px" }}
            value={month}
            onChange={(e) => setMonth(Number(e.target.value))}
            className="form-select d-inline w-auto me-2"
          >
            {months.map((m, index) => (
              <option key={index} value={index}>
                {m}
              </option>
            ))}
          </select>
          <select style={{ padding: "8px", marginLeft: "8px" }}
            value={year}
            onChange={(e) => setYear(Number(e.target.value))}
            className="form-select d-inline w-auto"
          >
            {Array.from(
              { length: 10 },
              (_, i) => new Date().getFullYear() - 5 + i
            ).map((yr) => (
              <option key={yr} value={yr}>
                {yr}
              </option>
            ))}
          </select>
        </div>
      </div>
      {/* Round Navigation */}
      <div className="d-flex w-full flex-row justify-content-between align-items-center mb-4">
        <div>
          {rounds.map((round, index) => (
            <button style={{ marginRight: "10px" }}
              key={index}
              className={`btn btn-outline-primary mx-1 ${activeRound._id === round._id ? "active btn-warning " : ""
                }`}
              onClick={() => { setActiveRound(round); fetchRankings(round._id) }}
            >
              Round {index + 1}
            </button>
          ))}
          <button
            className="btn btn-primary"
            onClick={() => setModalOpen(true)}
          >
            Add Round
          </button>
        </div>
        {activeRound?.matched == "0" ? <button style={{ marginBottom: "20px", marginTop: "20px", display: "flex", justifyContent: "flex-end" }}
          className=" btn btn-info"
          onClick={() => setMatchModalOpen(true)}
        >
          Match Users
        </button> : <div style={{ marginBottom: "20px", marginTop: "20px", display: "flex", justifyContent: "flex-end" }}>
          <button
            className="btn btn-success"
            onClick={() => approveRej("1")}
          >
            Approve
          </button>
          <button
            className="btn btn-danger"
            onClick={() => approveRej("2")}
          >
            Reject
          </button>
        </div>}
      </div>
      {rankings.length > 0 && selectedItems.length > 0 && <div className="mb-4">
        <button style={{ margin: "0 50%" }} className="btn btn-success" onClick={handleSelectedAction}>
          Submit for Next round
        </button>
      </div>}

      {/* Dropdown for Entries Per Page */}
      <div style={{ marginBottom: "20px", marginTop: "20px" }} className="d-flex justify-content-between align-items-center mb-3">
        <label htmlFor="entriesPerPage" className="me-2">
          Entries Per Page:
        </label>
        <select style={{ padding: "8px", marginLeft: "8px" }}
          id="entriesPerPage"
          className="form-select w-auto"
          value={limit}
          onChange={handleLimitChange}
        >
          <option value={10}>10</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>

      {/* Pagination Controls */}
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: "16px" }} className="d-flex justify-content-center my-3">
        <button
          className="btn btn-secondary"
          disabled={page === 1}
          onClick={() => handlePageChange(page - 1)}
        >
          Previous
        </button>
        <span className="mx-2 align-self-center">
          Page {page} of {totalPages}
        </span>
        <button
          className="btn btn-secondary"
          disabled={page === totalPages}
          onClick={() => handlePageChange(page + 1)}
        >
          Next
        </button>
        <div style={{ marginLeft: "10px", fontWeight: "bold" }}>Total:{totalCount}</div>
      </div>
      {/* Rankings Table */}
      <div className="table-responsive">
        {rounds.length > 0 ? <table className="table table-bordered table-striped">
          <thead className="table-dark">
            <tr>
              <th>
                <input
                  type="checkbox"
                  onChange={toggleSelectAll}
                  checked={selectAll}
                />
              </th>
              <th>Rank</th>
              <th>Family Name</th>
              <th>Leader</th>
              <th>Status</th>
              <th>Host Name</th>
              <th>VS</th>
              <th>Opponent Family</th>
              <th>Date/Time</th>
              <th>Winner</th>
              <th>Receive Star</th>
              <th>Slap Point</th>
              <th>Winning Point</th>
              <th>Total Point</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loading ? "Loading..." : rankings?.map((rank, index) => {
              // Calculate totals for the current host group
              const totalReceivingStars = rank?.host?.reduce((sum, item) => sum + (item?.receiveStars || 0), 0);
              const totalSlapPoints = rank?.host?.reduce((sum, item) => sum + (item?.slapPoints || 0), 0);
              const totalWinningPoints = rank?.host?.reduce((sum, item) => sum + (item?.winningPoints || 0), 0);
              const totalPoints = rank?.host?.reduce((sum, item) => sum + (item?.totalPoints || 0), 0);
              return (
                <React.Fragment key={index}>
                  <tr>
                    <td rowSpan={rank?.host?.length + 1}>
                      <input
                        type="checkbox"
                        checked={selectedItems.includes(rank.familyId)}
                        onChange={() => handleCheckboxChange(rank.familyId)}
                      />
                    </td>
                    <td rowSpan={rank?.host?.length + 1}>{(page - 1) * limit + index + 1}</td>
                    <td rowSpan={rank?.host?.length + 1}>{rank?.hostFamily?.name}</td>
                    <td rowSpan={rank?.host?.length + 1}>{rank?.hostFamily?.leader}</td>
                  </tr>
                  {rank?.host?.map((item, ind) => (
                    <tr key={ind}>
                      <td>
                        {item.liveStatus && (
                          <button
                            className={`btn ${item.liveStatus === "live"
                              ? "bg-success text-white"
                              : item.liveStatus === "upcoming"
                                ? "bg-warning text-white"
                                : item.liveStatus === "completed" && item.result == 0
                                  ? "bg-danger text-white"
                                  : item.liveStatus === "completed"
                                    ? "bg-blue text-white"
                                    : ""
                              }`}
                          >
                            {item.liveStatus === "completed" && item.result == 0
                              ? "Canceled"
                              : item.liveStatus.charAt(0).toUpperCase() + item.liveStatus.slice(1)}
                          </button>
                        )}
                      </td>
                      <td>{item?.host?.username}</td>
                      <td>{item?.host?.username} <div style={{ fontWeight: "bold", textAlign: "center" }}>VS</div> {item?.oponentHost?.username}</td>
                      <td>{item?.oponentFamily?.name}</td>
                      <td>
                        {isEditing===item?.roundId ? (
                          <div>
                            <input
                              type="datetime-local"
                              value={selectedDateTime}
                              onChange={handleDateChange}
                              className="date-input"
                            />
                            <button onClick={()=>handleEditClick("")} className="submit-button">
                              Cancel
                            </button>
                            <button onClick={()=>handleSubmit(item?.roundId)} className="submit-button">
                              Submit
                            </button>
                          </div>
                        ) : (
                          <div>
                            {item?.dateTime}
                            <span onClick={()=>handleEditClick(item?.roundId)} style={{ cursor: "pointer", marginLeft: "8px" }}>
                              🖉
                            </span>
                          </div>
                        )}
                      </td>
                      <td>{item?.winner}</td>
                      <td>{item?.receiveStars || 0}</td>
                      <td>{item?.slapPoints || 0}</td>
                      <td>{item?.winningPoints || 0}</td>
                      <td>{item?.totalPoints || 0}</td>
                      <td className="btn btn-primary" style={{ marginTop: "16px" }} onClick={() => {
                        setNewSettle({ id: item?._id, familyPkId: activeRound._id });
                        setModalOpenSettle(true)
                      }}>Edit</td>
                    </tr>
                  ))}
                  {/* Add a totals row */}
                  <tr className="font-bold" style={{ backgroundColor: "#D5F3C4" }}>
                    <td colSpan={10}>Total</td>
                    <td>{totalReceivingStars}</td>
                    <td>{totalSlapPoints}</td>
                    <td>{totalWinningPoints}</td>
                    <td>{rank?.points}</td>

                  </tr>
                  <hr />
                </React.Fragment>
              )
            })}
          </tbody>

        </table> : "No Rounds found"}
      </div>

      {/* Match Users Modal */}
      {matchModalOpen && (
        <div className="modal show d-block" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Match Users</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setMatchModalOpen(false)}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label className="form-label">Match Type</label>
                    <select
                      className="form-control"
                      value={matchType}
                      onChange={(e) => setMatchType(e.target.value)}
                    >
                      <option value="withdrawal">Withdrawal</option>
                      <option value="level">Level</option>
                      <option value="random">Random</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Match Date & Time</label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      value={matchDateTime}
                      onChange={(e) => setMatchDateTime(e.target.value)}
                      min={new Date().toISOString().slice(0, 16)} // Ensures only future date/time
                      required
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setMatchModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleMatchUsers}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Add Round Modal */}
      {modalOpen && (
        <div className="modal show d-block" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add New Round</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setModalOpen(false)}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label className="form-label">Round Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="roundName"
                      value={newRoundData.roundName}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Family Points</label>
                    <input
                      type="number"
                      className="form-control"
                      name="familyPoint"
                      value={newRoundData.familyPoints}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Max Members</label>
                    <input
                      type="number"
                      className="form-control"
                      name="maxMembers"
                      value={newRoundData.maxMembers}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Start Date</label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      name="startDate"
                      value={newRoundData.startDate}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">End Date</label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      name="endDate"
                      value={newRoundData.endDate}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Image</label>
                    <input
                      type="file"
                      className="form-control"
                      name="image"
                      onChange={handleFileChange}
                      required
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleAddRound}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {modalOpenSettle && (
        <div className="modal show d-block" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Points</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setModalOpen(false)}
                ></button>
              </div>
              <div className="modal-body">
                <form>

                  <div className="mb-3">
                    <label className="form-label">Rec. Stars</label>
                    <input
                      type="number"
                      className="form-control"
                      name="points"
                      value={newSettle.points}
                      onChange={handleInputChangeSettle}
                      required
                    />
                  </div>
                  {/* Radio Button Group */}
                  <div className="mb-3">
                    <label className="form-label">Outcome</label>
                    <div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="winner"
                          value="1"
                          onChange={(e) =>
                            setNewSettle((prev) => ({ ...prev, winner: e.target.value }))
                          }
                          required
                        />
                        <label className="form-check-label">Winner</label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="winner"
                          value="0"
                          onChange={(e) =>
                            setNewSettle((prev) => ({ ...prev, winner: e.target.value }))
                          }
                          required
                        />
                        <label className="form-check-label">Loser</label>
                      </div>
                    </div>
                  </div>

                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setModalOpenSettle(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSettle}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {matchSendStars && (
        <div className="modal show d-block" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Send Stars</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setModalOpen(false)}
                ></button>
              </div>
              <div className="modal-body">
                <form>

                  <div className="mb-3">
                    <label className="form-label"> Stars</label>
                    <input
                      type="number"
                      className="form-control"
                      name="amount"
                      id="amount"
                      required
                    />
                  </div>

                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setMatchSendStars(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => handleSendStars(document.getElementById("amount").value)}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {
        modaltransList && (
          <div className="modal show d-block" tabIndex="-1">
            <div className="modal-dialog" >
              <div className="modal-content" style={{ overflow: "scroll", height: "500px", marginTop: "12px", padding: "4px" }}>
                <div className="btn btn-primary" style={{ margin: "5% 50%" }} onClick={() => setmodalTransList(false)}>
                  Close
                </div>
                <table className="table table-bordered table-striped">
                  <th>Sno</th>
                  <th>UserName</th>
                  <th>Amount</th>
                  <th>Action</th>
                  {transList.map((item, index) => {
                    return (
                      <tr key={item._id} style={{ marginTop: "10px" }} >
                        <td>{index + 1}</td>
                        <td>{item?.userId?.username}</td>
                        <td>{item?.stars}</td>
                        <td className="btn btn-primary" onClick={() => handleDeductStars(item._id)}>Return</td>
                      </tr>
                    )
                  })}
                </table>
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default PKBattleComponent;
