import React, { useEffect, useState } from "react";
import "../Css/Aside.css";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy, FaCheck } from 'react-icons/fa'; // Import the copy and check icons from react-icons
const Aside = () => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
  };
  let storedName = sessionStorage.getItem("data");
  let details = JSON.parse(storedName);
  let agencyDetail = sessionStorage.getItem("agency");
  let AgencyDetails = JSON.parse(agencyDetail);
  let agencyADetail = sessionStorage.getItem("agencyadmin");
  let AgencyADetails = JSON.parse(agencyADetail);
  let bdd = sessionStorage.getItem("bd");
  let bD = JSON.parse(bdd);
  let coinDetail = sessionStorage.getItem("coin");
  let coinDetails = JSON.parse(coinDetail);
  let x = window.location.pathname.toString();
  let ans = x[0] + x[1] + x[2] + x[3] + x[4] + x[5];
  let agency = ans + x[6];
  let agencyAdmin = agency + x[7] + x[8] + x[9] + x[10] + x[11] + x[12];
  let bd = x[0] + x[1] + x[2];
  // console.log(bd);

  $('.sidebar-menu a[data-toggle="collapse"]').on("click", function () {
    // Collapse all other menus
    $(".collapse.show").removeClass("show");
    // Toggle the selected menu
    $($(this).attr("href")).toggleClass("show");
  });

  return (
    <>
      {ans === "/admin" ? (
        <aside
          className="main-sidebar"
          style={{ maxHeight: "95vh", overflow: "auto" }}
        >
          <section className="sidebar">
            <div className="user-panel">
              <div className="pull-left image">
                <img
                  src={`${process.env.REACT_APP_BASEURL1}${details.image}`}
                  className="img-circle"
                  alt="User Image"
                  style={{ height: "50px", width: "50px" }}
                />
              </div>
              <div className="pull-left info">
                <p>{details.name}</p>
                <a href="#">
                  <i className="fa fa-circle text-success" /> Online
                </a>
              </div>
            </div>
            <ul
              className="sidebar-menu"
              dataWidget="tree"
              id="iq-sidebar-toggle"
            >
              {/* ============================================================================== */}
              {/* MASTER */}
              {/* ============================================================================== */}
              <li className="active">
                <a href="/admin">
                  <i className="fa fa-dashboard" />
                  Dashboard
                </a>
              </li>
              <li className="">
                <Link to="/admin/balance">
                  <i class="fa-solid fa-coins"></i>
                  Balance
                </Link>
              </li>
              <li className="header">Splash Image</li>
              <li className="LINK_CLASS">
                <Link to="/admin/splashimage">
                  <i className="fa fa-diamond" />
                  Add splash Image
                </Link>
              </li>
              <li className="header">ACCOUNTS MANAGEMENT MASTER</li>
              {/* Master : Users e */}
              {/* ritul */}
              <li>
                <a
                  href="#user-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i className="fa fa-users" /> <span>Users</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="user-info"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="admin/manageUser">
                      <i className="fa fa-circle-o" />
                      View Users
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/liveUser">
                      <i className="fa fa-circle-o" />
                      Live User
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/pushMessage">
                      <i className="fa fa-circle-o" />
                      Push Messages
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/bannedUsers">
                      <i className="fa fa-circle-o" />
                      Banned Users
                    </Link>
                  </li>
                </ul>
              </li>
              {/* divansh */}
              {/* Master : Host s */}
              <li>
                <a
                  href="#Host-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i className="fa fa-user" /> <span>Host</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="Host-info"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/admin/applyHost">
                      <i className="fa fa-circle-o" />
                      Apply Host
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/pendingHost">
                      <i className="fa fa-circle-o" />
                      Pending Host Request
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/approvedHost">
                      <i className="fa fa-circle-o" />
                      Approved Host Request
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/rejectedHost">
                      <i className="fa fa-circle-o" />
                      Rejected Host Request
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/hostrecord">
                      <i className="fa fa-circle-o" />
                      Host Record
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  href="#Host-widthdrawal"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i className="fa fa-user" /> <span>WithDrawal</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="Host-widthdrawal"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  {/* <li>
                    <Link to="/admin/applyHost">
                      <i className="fa fa-circle-o" />
                      Apply Host
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/admin/widthdrawal/pending">
                      <i className="fa fa-circle-o" />
                      Pending WithDrawal
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/widthdrawal/approved">
                      <i className="fa fa-circle-o" />
                      Approved WithDrawal
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/widthdrawal/rejected">
                      <i className="fa fa-circle-o" />
                      Rejected WithDrawal
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/withdrawalbdlists">
                      <i className="fa fa-circle-o" />
                      Total WithDrawal
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/withdrawalfamilylists">
                      <i className="fa fa-circle-o" />
                      Family WithDrawal
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/storedwithdrawalbdlists">
                      <i className="fa fa-circle-o" />
                      Total With. Stored
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/storedwithdrawalfamilylists">
                      <i className="fa fa-circle-o" />
                      Family With. Stored
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  href="#F-widthdrawal"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i className="fa fa-user" /> <span>F. WithDrawal</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="F-widthdrawal"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  {/* <li>
                    <Link to="/admin/applyHost">
                      <i className="fa fa-circle-o" />
                      Apply Host
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/admin/pendingfamilywalletwith">
                      <i className="fa fa-circle-o" />
                      Pending WithDrawal
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/approvedfamilywalletwith">
                      <i className="fa fa-circle-o" />
                      Approved WithDrawal
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/rejectedfamilywalletwith">
                      <i className="fa fa-circle-o" />
                      Rejected WithDrawal
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/FamilyWalletWithdrawalAdmin">
                      <i className="fa fa-circle-o" />
                      Family Wallet With.
                    </Link>
                  </li>

                </ul>
              </li>
              {/* Master : premium s */}
              <li>
                <a
                  href="#Family-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i
                    class="fa-regular fa-star"
                    style={{ marginRight: "4px" }}
                  ></i>
                  <span>Family</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="Family-info"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/admin/familycount">
                      <i className="fa fa-circle-o" />
                      Manage Family
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/familyleave">
                      <i className="fa fa-circle-o" />
                      Leave Family
                    </Link>
                  </li>
                </ul>
              </li>
              {/* Vulgar : premium s */}
              <li className="LINK_CLASS">
                <Link to="admin/sendingRecievingRanking">
                  <i className="fa fa-diamond" />
                  SendingRecieving Rank
                </Link>
              </li>
              <li className="LINK_CLASS">
                <Link to="admin/removedranking">
                  <i className="fa fa-diamond" />
                  Remove Star Rank
                </Link>
              </li>
              <li className="LINK_CLASS">
                <Link to="admin/GetTargetWithdrawl">
                  <i className="fa fa-diamond" />
                  Target Withdrawl
                </Link>
              </li>
              <li className="LINK_CLASS">
                <Link to="admin/livetime">
                  <i className="fa fa-diamond" />
                  Live Time
                </Link>
              </li>
              <li className="LINK_CLASS">
                <Link to="admin/videos">
                  <i className="fa fa-diamond" />
                  Videos
                </Link>
              </li>
              <li>
                <a
                  href="#Vulgar-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i
                    class="fa-regular fa-star"
                    style={{ marginRight: "4px" }}
                  ></i>
                  <span>Vulgar</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="Vulgar-info"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/admin/vulgar">
                      <i className="fa fa-circle-o" />
                      View Vulgar Lists
                    </Link>
                  </li>
                </ul>
              </li>
              {/* secretkey : premium s */}
              <li>
                <a
                  href="#secretkey-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i
                    class="fa-regular fa-star"
                    style={{ marginRight: "4px" }}
                  ></i>
                  <span>Secretkey</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="secretkey-info"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/admin/secretkey">
                      <i className="fa fa-circle-o" />
                      View Secretkeys
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="header">Other Pannel</li>
              {/* Master : premium s */}
              <li>
                <a
                  href="#bd-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i class="fa fa-coins"></i>
                  <span>BD Panel</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="bd-info"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/admin/addBd">
                      <i className="fa fa-circle-o" />
                      Add BD Panel
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/manageBd">
                      <i className="fa fa-circle-o" />
                      Manage BD Panel
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  href="#coin-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i class="fa fa-coins"></i>
                  <span>Coin Panel</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="coin-info"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/admin/addCoin">
                      <i className="fa fa-circle-o" />
                      Add Coin Agency
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/manageCoin">
                      <i className="fa fa-circle-o" />
                      Manage Coin Agency
                    </Link>
                  </li>
                </ul>
              </li>
              {/* Agency Adminpanel */}
              <li>
                <a
                  href="#AgencyAdminManage-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i class="fa fa-user-secret"></i>{" "}
                  <span>Agency Admin Panel </span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="AgencyAdminManage-info"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/admin/addagencyadmin">
                      <i className="fa fa-circle-o" />
                      Add Agency Admin
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/manageagencyadmin">
                      <i className="fa fa-circle-o" />
                      Manage Agency Admin
                    </Link>
                  </li>
                </ul>
              </li>
              {/* Master : Agency s */}
              <li>
                <a
                  href="#AgencyManage-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i class="fa fa-user-secret"></i> <span>Agency Panel</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="AgencyManage-info"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/admin/addAgency">
                      <i className="fa fa-circle-o" />
                      Add Agency
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/manageAgency">
                      <i className="fa fa-circle-o" />
                      Manage Agency
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/agencyRanking">
                      <i className="fa fa-circle-o" />
                      Agency Ranking
                    </Link>
                  </li>
                </ul>
              </li>
              {/* ================================= */}
              {/* Badge & Gifts */}
              {/* ================================= */}
              <li className="header">BADGES &amp; GIFT</li>
              {/* Master : premium s */}
              <li>
                <a
                  href="#Vip-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i
                    class="fa-regular fa-star"
                    style={{ marginRight: "4px" }}
                  ></i>{" "}
                  <span>Vip Badges</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="Vip-info"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/admin/addVip">
                      <i className="fa fa-circle-o" />
                      Add VIP Badges
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/manageVip">
                      <i className="fa fa-circle-o" />
                      Manage VIP Badges
                    </Link>
                  </li>
                </ul>
              </li>
              {/* Master : Banners s */}
              <li>
                <a
                  href="#Banners-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i
                    class="fa-regular fa-clone"
                    style={{ marginRight: "5px" }}
                  ></i>{" "}
                  <span>Banners</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="Banners-info"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/admin/addBanner">
                      <i className="fa fa-circle-o" />
                      Add Banners
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/manageBanner">
                      <i className="fa fa-circle-o" />
                      Manage Banners
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link
                  to="/admin/addd"

                >
                  <i
                    class="fa-regular fa-clone"
                    style={{ marginRight: "5px" }}
                  ></i>{" "}
                  <span>Ads</span>

                </Link>
              </li>

              {/* Master : Official tag s */}
              <li>
                <a
                  href="#ev-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i class="fa fa-tag"></i> <span>Events</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="ev-info"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/admin/addEvent">
                      <i className="fa fa-circle-o" />
                      Add Event
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/GetEvents">
                      <i className="fa fa-circle-o" />
                      View Events
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  href="#pk-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i class="fa fa-tag"></i> <span>FamilyPk</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="pk-info"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/admin/addslap">
                      <i className="fa fa-circle-o" />
                      Add Slap
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/viewslap">
                      <i className="fa fa-circle-o" />
                      View Slap
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/addwinningslap">
                      <i className="fa fa-circle-o" />
                      Add WinningSlap
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/viewwinningslap">
                      <i className="fa fa-circle-o" />
                      View WinningSlap
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/FamilyPkRuleForm">
                      <i className="fa fa-circle-o" />
                      Add FamilyPkRules
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/ViewFamilyPkRuleForm">
                      <i className="fa fa-circle-o" />
                      View FamilyPkRules
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/PKBattleComponent">
                      <i className="fa fa-circle-o" />
                      FamilyPk
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/FamilyPKBattle">
                      <i className="fa fa-circle-o" />
                      CreatePkBanner-Final
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  href="#Officialtags-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i class="fa fa-tag"></i> <span>Official Tags</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="Officialtags-info"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/admin/addTag">
                      <i className="fa fa-circle-o" />
                      Add Official Tag
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/manageTag">
                      <i className="fa fa-circle-o" />
                      Manage Official Tag
                    </Link>
                  </li>
                </ul>
              </li>
              {/* Master : Official Songs */}
              <li>
                <a
                  href="#Officialsongs-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i class="fa fa-music"></i> <span>Songs</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="Officialsongs-info"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/admin/addSongs">
                      <i className="fa fa-circle-o" />
                      Add Songs
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/manageSongs">
                      <i className="fa fa-circle-o" />
                      Manage Songs
                    </Link>
                  </li>
                </ul>
              </li>
              {/* Master : Official Plan */}
              <li>
                <a
                  href="#Officialplan-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i class="fa fa-money-check-dollar"></i> <span>Plans</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="Officialplan-info"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/admin/addPlan">
                      <i className="fa fa-circle-o" />
                      Add Plan
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/managePlan">
                      <i className="fa fa-circle-o" />
                      Manage Plan
                    </Link>
                  </li>
                </ul>
              </li>
              {/* Master : Official Video Content */}
              <li>
                <a
                  href="#OfficialVideoContent-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i class="fa fa-file-video"></i> <span>Video Content</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="OfficialVideoContent-info"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/admin/addContent">
                      <i className="fa fa-circle-o" />
                      Add Content
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/manageContent">
                      <i className="fa fa-circle-o" />
                      Manage Content
                    </Link>
                  </li>
                </ul>
              </li>
              {/* Master : Official Gift */}
              <li>
                <a
                  href="#Gift-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i
                    class="fa-solid fa-gift"
                    style={{ marginRight: "8px" }}
                  ></i>{" "}
                  <span> Gift</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="Gift-info"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/admin/addGift">
                      <i className="fa fa-circle-o" />
                      Add Gift
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/manageGift">
                      <i className="fa fa-circle-o" />
                      View Gift
                    </Link>
                  </li>
                </ul>
              </li>
              {/* Master : Live Gifts s */}
              <li>
                <a
                  href="#LiveGift-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i class="fa fa-hand-holding-heart"></i>{" "}
                  <span> Live Gift</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="LiveGift-info"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/admin/manageLiveGiftCategory">
                      <i className="fa fa-circle-o" />
                      Gift Category
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/addLiveGift">
                      <i className="fa fa-circle-o" />
                      Add Live Gift
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/manageLiveGift">
                      <i className="fa fa-circle-o" />
                      View Live Gift
                    </Link>
                  </li>
                </ul>
              </li>
              {/* Master : Mall s */}
              <li className="header">Store</li>
              {/* Entry Effect s */}
              <li className="LINK_CLASS">
                <Link to="admin/FamilyPKBattle">
                  <i className="fa fa-diamond" />
                  Create PKBanner
                </Link>
              </li>
              <li>
                <a
                  href="#Entryeffect-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i
                    class="fa-solid fa-gopuram"
                    style={{ marginRight: "5px" }}
                  ></i>{" "}
                  <span>Entry Effects</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="Entryeffect-info"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/admin/addEntryEffect">
                      <i className="fa fa-circle-o" />
                      Add Entry Effects
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/manageEntryEffect">
                      <i className="fa fa-circle-o" />
                      View Entry Effects
                    </Link>
                  </li>
                </ul>
              </li>
              {/* Frame s */}
              <li>
                <a
                  href="#Frames-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i class="fa fa-border-top-left"></i> <span>Frames</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="Frames-info"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/admin/addFrames">
                      <i className="fa fa-circle-o" />
                      Add Frame
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/manageFrames">
                      <i className="fa fa-circle-o" />
                      View Frame
                    </Link>
                  </li>
                </ul>
              </li>
              {/* Wallpaper s */}
              <li>
                <a
                  href="#Wallpaper-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i
                    class="fa-brands fa-ethereum"
                    style={{ marginRight: "5px" }}
                  ></i>{" "}
                  <span>Theme</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="Wallpaper-info"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/admin/addTheme">
                      <i className="fa fa-circle-o" />
                      Add Theme
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/manageTheme">
                      <i className="fa fa-circle-o" />
                      View Theme
                    </Link>
                  </li>
                </ul>
              </li>
              {/* backgroundtheme */}
              <li>
                <a
                  href="#Wallpaper-infoo"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i
                    class="fa-brands fa-ethereum"
                    style={{ marginRight: "5px" }}
                  ></i>{" "}
                  <span>Background Theme</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="Wallpaper-infoo"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/admin/backgroundtheme">
                      <i className="fa fa-circle-o" />
                      View Background Theme
                    </Link>
                  </li>
                </ul>
              </li>
              {/* =============================== */}
              <li className="header">Commission Table</li>
              {/* Master : Recharge s */}
              <li>
                <a
                  href="#comm-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i class="fa fa-bolt"></i> <span>Commission</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="comm-info"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/admin/addcommission">
                      <i className="fa fa-circle-o" />
                      Add Commission
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/viewcommission">
                      <i className="fa fa-circle-o" />
                      View Commission
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/addconversion">
                      <i className="fa fa-circle-o" />
                      Add Conversion
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/viewconversion">
                      <i className="fa fa-circle-o" />
                      View Conversion
                    </Link>
                  </li>
                </ul>
              </li>
              {/* Level */}
              {/* =============================== */}
              <li className="header">LEVELS</li>
              {/* Master : level s */}
              <li>
                <a
                  href="#Mylevel-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i class="fa fa-list-check"></i> <span>My Level</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="Mylevel-info"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/admin/manageLevel">
                      <i className="fa fa-circle-o" />
                      Manage Levels
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/addLevel">
                      <i className="fa fa-circle-o" />
                      Add Level
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/addentryEffectslevel">
                      <i className="fa fa-circle-o" />
                      Add EntryEffects Levels
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/entryEffectslevel">
                      <i className="fa fa-circle-o" />
                      EntryEffects Levels
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/addframelevel">
                      <i className="fa fa-circle-o" />
                      Add Frame Levels
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/framelevel">
                      <i className="fa fa-circle-o" />
                      Frame Levels
                    </Link>
                  </li>
                </ul>
              </li>
              {/* =============================== */}
              {/* Recharge */}
              {/* =============================== */}
              <li className="header">RECHARGE</li>
              {/* Master : Recharge s */}
              <li>
                <a
                  href="#recharge-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i class="fa fa-bolt"></i> <span>Recharge</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="recharge-info"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/admin/sendCoins">
                      <i className="fa fa-circle-o" />
                      Send Coins
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/rechargeHistory">
                      <i className="fa fa-circle-o" />
                      Recharge History
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/returncoins">
                      <i className="fa fa-circle-o" />
                      Return coins
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/Payments">
                      <i className="fa fa-circle-o" />
                      Razorpay Payments
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/GamesData">
                      <i className="fa fa-circle-o" />
                      Games Payments
                    </Link>
                  </li>
                </ul>
              </li>
              {/* =============================== */}
              {/* Admin Account */}
              {/* =============================== */}
              <li className="header">Admin Account</li>
              <li className="LINK_CLASS">
                <Link to="admin/profile">
                  <i className="fa fa-diamond" />
                  Account
                </Link>
              </li>
              <li
                onClick={() => {
                  sessionStorage.removeItem("data");
                  window.location.href = "/";
                }}
              >
                <Link href="/admin/logout">
                  <i className="fa fa-sign-out" />
                  Sign Out
                </Link>
              </li>
            </ul>
          </section>

          {/* /.sidebar */}
        </aside>
      ) : agencyAdmin === "/agencyadmin/" ? (
        <aside
          className="main-sidebar"
          style={{ maxHeight: "95vh", overflow: "auto" }}
        >
          <section className="sidebar" >
            <div className="user-panel" style={{ minHeight: "15vh", margin: "2px" }}>
              <div className="pull-left image">
                <img
                  src={`${process.env.REACT_APP_BASEURL1}${AgencyADetails?.image}`}
                  className="img-circle"
                  alt="User Image"
                  style={{ height: "50px", width: "50px" }}
                />
              </div>
              <div className="pull-left info">
                <p>{AgencyADetails?.name}</p>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={{ fontSize: "9px", margin: 0 }}>{AgencyADetails?.uniqueId}</p>
                  <CopyToClipboard text={AgencyADetails?.uniqueId} onCopy={handleCopy}>
                    {copied ? (
                      <FaCheck style={{ marginLeft: '4px', cursor: 'pointer', color: 'green' }} />
                    ) : (
                      <FaCopy style={{ marginLeft: '4px', cursor: 'pointer' }} />
                    )}
                  </CopyToClipboard>
                </div>
                <a href="#">
                  <i className="fa fa-circle text-success" /> Online
                </a>
              </div>
            </div>
            <ul
              className="sidebar-menu"
              dataWidget="tree"
              id="iq-sidebar-toggle"
            >
              {/* ============================================================================== */}
              {/* MASTER */}
              {/* ============================================================================== */}
              <li className="active">
                <Link to="/agencyadmin/dashboard">
                  <i className="fa fa-dashboard" />
                  Agency Admin Panel
                </Link>
              </li>
              {/* =============================== */}
              {/* Account Management */}
              {/* =============================== */}
              <li className="header">ACCOUNTS MANAGEMENT</li>
              {/* Master : Users e */}
              {/* ritul */}

              <li>
                <a
                  href="#user-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i className="fa fa-user" /> <span>Agency </span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="user-info"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/agencyadmin/addagency">
                      <i className="fa fa-circle-o" />
                      Add Agency
                    </Link>
                  </li>
                  <li>
                    <Link to="/agencyadmin/manageagency">
                      <i className="fa fa-circle-o" />
                      Manage Agency
                    </Link>
                  </li>
                </ul>
              </li>
              {/* ================= */}
              <li>
                <a
                  href="#host-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i className="fa fa-user" /> <span>Host </span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="host-info"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/agencyadmin/pendinghost">
                      <i className="fa fa-circle-o" />
                      Pending Hosts
                    </Link>
                  </li>
                  <li>
                    <Link to="/agencyadmin/rejectedhost">
                      <i className="fa fa-circle-o" />
                      Rejected Hosts
                    </Link>
                  </li>
                  <li>
                    <Link to="/agencyadmin/approvedhost">
                      <i className="fa fa-circle-o" />
                      Approved Hosts
                    </Link>
                  </li>
                </ul>
              </li>
              {/* ================= */}
              <li>
                <a
                  href="#host-info-w"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i className="fa fa-user" /> <span>Withdrawal </span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="host-info-w"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/agencyadmin/agencywithdrawal">
                      <i className="fa fa-circle-o" />
                      Total Withdrawal
                    </Link>
                  </li>

                </ul>
              </li>
              <li>
                <Link
                  to="/agencyadmin/Seniors"

                >
                  <i className="fa fa-user" /> <span>Seniors </span>
                  <span className="pull-right-container">
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/agencyadmin/getTargetWith"

                >
                  <i className="fa fa-user" /> <span>Set Target Withdrawal </span>
                  <span className="pull-right-container">
                  </span>
                </Link>
              </li>

              <li className="header">Admin Account</li>
              <li className="LINK_CLASS">
                <Link to="/agencyadmin/aaprofile">
                  <i className="fa fa-diamond" />
                  Account
                </Link>
              </li>
              <li
                onClick={() => {
                  sessionStorage.removeItem("agencyadmin");
                  setTimeout(() => {
                    window.location.pathname = "/agencyadmin";
                  });
                }}
              >
                <a>
                  <i className="fa fa-sign-out" />
                  Sign Out
                </a>
              </li>
            </ul>
          </section>
          {/* /.sidebar */}
        </aside>
      ) : agency === "/agency" ? (
        <aside
          className="main-sidebar"
          style={{ maxHeight: "95vh", overflow: "auto" }}
        >
          <section className="sidebar">
            <div className="user-panel" style={{ minHeight: "15vh", margin: "2px" }}>
              <div className="pull-left image">
                <img
                  src={`${process.env.REACT_APP_BASEUR1}${AgencyDetails?.image}`}
                  className="img-circle"
                  alt="User Image"
                  style={{ height: "50px", width: "50px" }}
                />
              </div>
              <div className="pull-left info">
                <p>{AgencyDetails?.name}</p>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={{ fontSize: "9px", margin: 0 }}>{AgencyDetails?.uniqueId}</p>
                  <CopyToClipboard text={AgencyDetails?.uniqueId} onCopy={handleCopy}>
                    {copied ? (
                      <FaCheck style={{ marginLeft: '4px', cursor: 'pointer', color: 'green' }} />
                    ) : (
                      <FaCopy style={{ marginLeft: '4px', cursor: 'pointer' }} />
                    )}
                  </CopyToClipboard>
                </div>
                <a href="#">
                  <i className="fa fa-circle text-success" /> Online
                </a>
              </div>
            </div>
            <ul
              className="sidebar-menu"
              dataWidget="tree"
              id="iq-sidebar-toggle"
            >
              {/* ============================================================================== */}
              {/* MASTER */}
              {/* ============================================================================== */}
              <li className="active">
                <Link to="/agency/dashboard">
                  <i className="fa fa-dashboard" />
                  Dashboard Agency Panel
                </Link>
              </li>
              {/* =============================== */}
              {/* Account Management */}
              {/* =============================== */}
              <li className="header">ACCOUNTS MANAGEMENT</li>
              {/* Master : Users e */}
              {/* ritul */}
              <li>
                <a
                  href="#user-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i className="fa fa-user" /> <span>Agency Host</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="user-info"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/agency/pendingHost">
                      <i className="fa fa-circle-o" />
                      Pending Hosts
                    </Link>
                  </li>
                  <li>
                    <Link to="/agency/rejectedHost">
                      <i className="fa fa-circle-o" />
                      Rejected Hosts
                    </Link>
                  </li>
                  <li>
                    <Link to="/agency/approvedHost">
                      <i className="fa fa-circle-o" />
                      Approved Hosts
                    </Link>
                  </li>
                </ul>
              </li>
              {/* divansh */}

              {/* Master : Host s */}
              <li>
                {/* <a
                  href="#Host-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                > */}
                <Link to="/agency/hostrecord">
                  <i className="fa fa-user" /> <span>Host Record</span>
                </Link>
                {/* <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span> */}
                {/* </a> */}
              </li>
              <li>
                {/* <a
                  href="#Host-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                > */}
                <Link to="/agency/hostwithdrawal">
                  <i className="fa fa-user" /> <span>Total Withdrawal</span>
                </Link>
                {/* <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span> */}
                {/* </a> */}
              </li>
              <li>
                <Link
                  to="/agency/Seniors"

                >
                  <i className="fa fa-user" /> <span>Seniors </span>
                  <span className="pull-right-container">
                  </span>
                </Link>
              </li>

              {/* =============================== */}
              {/* Admin Account */}
              {/* =============================== */}
              <li className="header">Agency Account</li>
              <li className="LINK_CLASS">
                <Link to="/agency/profile">
                  <i className="fa fa-diamond" />
                  Account
                </Link>
              </li>
              <li
                onClick={() => {
                  sessionStorage.removeItem("agency");
                  setTimeout(() => {
                    window.location.pathname = "/agency";
                  });
                }}
              >
                <a>
                  <i className="fa fa-sign-out" />
                  Sign Out
                </a>
              </li>
            </ul>
          </section>
          {/* /.sidebar */}
        </aside>
      ) : bd === "/bd" ? (
        <aside
          className="main-sidebar"
          style={{ maxHeight: "95vh", overflow: "auto" }}
        >
          <section className="sidebar">
            <div className="user-panel" style={{ minHeight: "15vh", margin: "2px" }}>
              <div className="pull-left image">
                <img
                  src={`${process.env.REACT_APP_BASEURL1}${bD?.image}`}
                  className="img-circle"
                  alt="User Image"
                  style={{ height: "50px", width: "50px" }}
                />
              </div>
              <div className="pull-left info">
                <p>{bD?.name}</p>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={{ fontSize: "9px", margin: 0 }}>{bD?.uniqueId}</p>
                  <CopyToClipboard text={bD?.uniqueId} onCopy={handleCopy}>
                    {copied ? (
                      <FaCheck style={{ marginLeft: '4px', cursor: 'pointer', color: 'green' }} />
                    ) : (
                      <FaCopy style={{ marginLeft: '4px', cursor: 'pointer' }} />
                    )}
                  </CopyToClipboard>
                </div>
                <a href="#">
                  <i className="fa fa-circle text-success" /> Online
                </a>
              </div>
            </div>
            <ul
              className="sidebar-menu"
              dataWidget="tree"
              id="iq-sidebar-toggle"
            >
              <li className="active">
                <Link to="/bd/dashboard">
                  <i className="fa fa-dashboard" />
                  Dashboard BD Panel
                </Link>
              </li>

              <li className="header">ACCOUNTS MANAGEMENT</li>
              <li>
                <a
                  href="#user-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i className="fa fa-user" /> <span>Agency Admin</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="user-info"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/bd/addagencyadmin">
                      <i className="fa fa-circle-o" />
                      Add Agency Admin
                    </Link>
                  </li>
                  <li>
                    <Link to="/bd/manageagencyadmin">
                      <i className="fa fa-circle-o" />
                      Manage Agency Admin
                    </Link>
                  </li>
                </ul>
              </li>
              {/* //////////////////////// */}
              <li>
                <a
                  href="#user-agency"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i className="fa fa-user" /> <span>Agency </span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="user-agency"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/bd/addagency">
                      <i className="fa fa-circle-o" />
                      Add Agency
                    </Link>
                  </li>
                  <li>
                    <Link to="/bd/manageagency">
                      <i className="fa fa-circle-o" />
                      Manage Agency
                    </Link>
                  </li>
                  <li>
                    <Link to="/bd/pendingAgencyListReq">
                      <i className="fa fa-circle-o" />
                      Pending Agency
                    </Link>
                  </li>
                </ul>
              </li>
              {/* /////////////////////// */}
              <li>
                <a
                  href="#bdhost-info"
                  class="collapsed"
                  data-toggle="collapse"
                  aria-expanded="false"
                >
                  <i className="fa fa-user" /> <span>Agency Admin Host</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul
                  id="bdhost-info"
                  class="collapse nestedullistss"
                  data-parent="#iq-sidebar-toggle"
                >
                  <li>
                    <Link to="/bd/pandinghost">
                      <i className="fa fa-circle-o" />
                      Pending Host
                    </Link>
                  </li>
                  <li>
                    <Link to="/bd/approvedhost">
                      <i className="fa fa-circle-o" />
                      Approved Host
                    </Link>
                  </li>
                  <li>
                    <Link to="/bd/rejectedhost">
                      <i className="fa fa-circle-o" />
                      Rejected Host
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={"/bd/liveuser"}>
                  <a >
                    <i className="fa fa-user" /> <span>User Live </span>
                  </a>
                </Link>
              </li>
              <li>
                <Link to={"/bd/FamilyWalletWithdrawalBD"}>
                  <a>
                    <i className="fa fa-user" /> <span>Family WithDrawal </span>
                  </a>
                </Link>
              </li>
              {/* <li>
                <Link to={"/bd/storedwithdrawalfamilyAAlists"}>
                  <a>
                    <i className="fa fa-user" /> <span>Family WithDrawal </span>
                  </a>
                </Link>
              </li> */}
              <li>
                <Link to={"/bd/list"}>
                  <a>
                    <i className="fa fa-user" /> <span>Total WithDrawal </span>
                  </a>
                </Link>
              </li>
              <li>
                <Link to={"/bd/bannedusers"}>
                  <a>
                    <i className="fa fa-user" /> <span>Banned Users </span>
                  </a>
                </Link>
              </li>
              <li>
                <Link to={"bd/getTargetWith"}>
                  <a>
                    <i className="fa fa-user" /> <span>Set Target Withdrawal </span>
                  </a>
                </Link>
              </li>
              {bD && bD?.uniqueId == "bd@6319098566" && <li className="LINK_CLASS">
                <Link to={"bd/FamilyPKBattle"}>
                  <i className="fa fa-diamond" />
                  Create PkBanner
                </Link>
              </li>}

              {/* /////////////////////// */}
              {/* Bd Host */}
              <li className="header">BD Account</li>
              <li className="LINK_CLASS">
                <Link to="/bd/profile">
                  <i className="fa fa-diamond" />
                  Account
                </Link>
              </li>
              <li
                onClick={() => {
                  sessionStorage.removeItem("bd");
                  setTimeout(() => {
                    window.location.pathname = "/bd";
                  });
                }}
              >
                <a>
                  <i className="fa fa-sign-out" />
                  Sign Out
                </a>
              </li>
            </ul>
          </section>
          {/* /.sidebar */}
        </aside>
      ) : (
        <aside
          className="main-sidebar"
          style={{ maxHeight: "95vh", overflow: "auto" }}
        >
          <section className="sidebar">
            <div className="user-panel" style={{ minHeight: "15vh", margin: "2px" }}>
              <div className="pull-left image">
                <img
                  src={`${process.env.REACT_APP_BASEURL1}${coinDetails?.image}`}
                  className="img-circle"
                  alt="User Image"
                  style={{ height: "50px", width: "50px" }}
                />
              </div>
              <div className="pull-left info">
                <p>{coinDetails?.name}</p>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={{ fontSize: "9px", margin: 0 }}>{coinDetails?.uniqueId}</p>
                  <CopyToClipboard text={coinDetails?.uniqueId} onCopy={handleCopy}>
                    {copied ? (
                      <FaCheck style={{ marginLeft: '4px', cursor: 'pointer', color: 'green' }} />
                    ) : (
                      <FaCopy style={{ marginLeft: '4px', cursor: 'pointer' }} />
                    )}
                  </CopyToClipboard>
                </div>
                <a href="#">
                  <i className="fa fa-circle text-success" /> Online
                </a>
              </div>
            </div>
            <ul
              className="sidebar-menu"
              dataWidget="tree"
              id="iq-sidebar-toggle"
            >
              {/* ============================================================================== */}
              {/* MASTER */}
              {/* ============================================================================== */}
              <li className="active">
                <Link to="/coin/dashboard">
                  <i className="fa fa-dashboard" />
                  Dashboard Coin Panel
                </Link>
              </li>

              <li className="header">ACCOUNTS MANAGEMENT master</li>

              <li>
                <Link to="/coin/sendcoin">
                  <a
                    href="#user-info"
                    // class="collapsed"
                    data-toggle="collapse"
                    aria-expanded="false"
                  >
                    <i className="fa fa-user" /> <span>Send Coins</span>
                    <span className="pull-right-container">
                      {/* <i className="fa fa-angle-left pull-right" /> */}
                    </span>
                  </a>
                </Link>
              </li>
              <li>
                <Link to="/coin/history">
                  <a
                    href="#user-info"
                    // class="collapsed"
                    data-toggle="collapse"
                    aria-expanded="false"
                  >
                    <i className="fa fa-user" /> <span>Coin History</span>
                    <span className="pull-right-container">
                      {/* <i className="fa fa-angle-left pull-right" /> */}
                    </span>
                  </a>
                </Link>
              </li>

              <li>
                <Link to="/coin/received">
                  <a
                    href="#user-info"
                    // class="collapsed"
                    data-toggle="collapse"
                    aria-expanded="false"
                  >
                    <i className="fa fa-user" /> <span>Receive History</span>
                    <span className="pull-right-container">
                      {/* <i className="fa fa-angle-left pull-right" /> */}
                    </span>
                  </a>
                </Link>
              </li>
              <li>
                <Link to="/coin/returnHistory">
                  <a
                    href="#user-info"
                    // class="collapsed"
                    data-toggle="collapse"
                    aria-expanded="false"
                  >
                    <i className="fa fa-user" /> <span>Return History</span>
                    <span className="pull-right-container">
                      {/* <i className="fa fa-angle-left pull-right" /> */}
                    </span>
                  </a>
                </Link>
              </li>
              <li className="header">Admin Account</li>
              <li className="LINK_CLASS">
                <Link to="/coin/profile">
                  <i className="fa fa-diamond" />
                  Account
                </Link>
              </li>
              <li
                onClick={() => {
                  sessionStorage.removeItem("coin");
                  setTimeout(() => {
                    window.location.pathname = "/coin";
                  });
                }}
              >
                <a>
                  <i className="fa fa-sign-out" />
                  Sign Out
                </a>
              </li>
            </ul>
          </section>
          {/* /.sidebar */}
        </aside>
      )}
    </>
  );
};

export default Aside;
