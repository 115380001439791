import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { global } from "../../Context/Context";
import $ from "jquery";
import { toast } from "react-toastify";
import axiosInstance from "../../config/axiosInterceptor"

const ViewConversion = () => {
  const baseUrl = process.env.REACT_APP_BASEURL1;
const [data,setData] = useState([]);
const [de,setDe] = useState(0);
  useEffect(() => {
    getCommission();
  }, [de]);
  const getCommission = async()=>{
    try {
      let res = await axiosInstance.get(`${process.env.REACT_APP_BASEURL5}/admin/commission/getConversion`)
      
      if(res.data.success==1){
        const sortedData = res.data.data.sort((a, b) => parseFloat(a.coins) - parseFloat(b.coins));
        setData(sortedData);
      }

    } catch (error) {
      toast.error(error.message)
    }
  }
  // Logic to paginate the users
 
  return (
    <>
      <section className="content-header">
        <h1>Manage Conversion</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Manage Conversion</li>
        </ol>
      </section>
      {/* Main content */}
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-header">
                <h3 className="box-title">
                  <Link
                    to="/admin/addconversion"
                    style={{ fontSize: "14px" }}
                    className="btn btn-block btn-success btn-xs"
                  >
                    Add More Conversion
                  </Link>
                </h3>
              </div>
              {/* Box Body */}
              <div className="box-body" style={{ padding: 0 }}>
                <div className="row " style={{ padding: "16px 0px" }}>
                  <div className="entries-per-page col-md-6 ">
                  </div>
                </div>

                {/* Table */}
                <table
                  id="example1"
                  className="table table-bordered table-striped"
                  style={{ marginBottom: 0 }}
                >
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center" }}>Sr.</th>
                      <th style={{ textAlign: "center" }}>Stars</th>
                      <th style={{ textAlign: "center" }}>M-Coins</th>
                      <th style={{ textAlign: "center" }}>Action</th>
                    </tr>
                  </thead>
                  {
                    data.length===0?<>Loading ...</>:<tbody id="ts">
                    {Array.isArray(data) &&
                      data.map((item, index) => {
        
                        return (
                          <tr className="text-center">
                            <td>{index+1}</td>
                            <td>{item?.coins}</td>
                            <td>{item?.recCoins}</td>
                            
                            <td>
                              <ul
                                className="admin-action btn btn-default"
                                style={{
                                  backgroundColor: "#f4f4f4",
                                  color: "#fff !important",
                                }}
                              >
                                <li class="dropdown">
                                  <a
                                    className="dropdown-toggle"
                                    style={{ color: "black" }}
                                    data-toggle="dropdown"
                                    href="#"
                                    aria-expanded="false"
                                  >
                                    Action <span className="caret" />
                                  </a>
                                  <ul className="dropdown-menu dropdown-menu-right">
                                    <li role="presentation">
                                      <Link
                                        role="menuitem"
                                        tabIndex={-1}
                                        onClick={async() => {
                                          const confirmDelete = window.confirm(
                                            "Are you sure you want to delete this item?"
                                          );
                                          if (confirmDelete) {
                                            let res = await axiosInstance.delete(`${process.env.REACT_APP_BASEURL5}/admin/commission/deleteConversion/${item._id}`)
                                                if (res.data.success === 1) {
                                                  toast.success(res.data.message);
                                                  setDe(de+1);
                                                } else {
                                                  toast.error(res.data.message);
                                                }
                                          }
                                        }}
                                      >
                                        Delete
                                      </Link>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        );
                      })}
                    {/* Table Body Content */}
                  </tbody>
                  }
                  
                </table>
                {/* Pagination */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewConversion;
