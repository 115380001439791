import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { global } from "../../Context/Context";
import axios from "axios";
import { toast } from "react-toastify";
import axiosInstance from "../../config/axiosInterceptor";

const RechargeHistory = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(5);
  const [total, setTotal] = useState(5);
  const baseUrl = process.env.REACT_APP_BASEURL1;
  const [adminrechargeHistory, setadminrechargeHistory] = useState([]);

  const adminrechargeHistorys = async () => {
    try {
      let data = await fetch(`${process.env.REACT_APP_BASEURL1}/admin/recharge/getrechargeHistory?page=${currentPage}&limit=${entriesPerPage}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      // console.log(data);
      let a = await data.json();
      setadminrechargeHistory(a.data);
      setTotal(a.total);
    } catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    adminrechargeHistorys();
  }, [entriesPerPage,currentPage]);

  // Logic to paginate the users
  
  // Pagination
  const totalPages = Math.ceil(total / entriesPerPage);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`} // Apply active style conditionally
        >
          <button onClick={() => setCurrentPage(i)} className="page-link">
            {i}
          </button>
        </li>
      );
    }

   
    return pageNumbers;
  };
  const submitHandler = async (dataDetails) => {
    // e.preventDefault();
    try {
      
    
    const { data } = await axiosInstance.post(
      `${process.env.REACT_APP_BASEURL5}/admin/return/coins`,
      dataDetails
    );
    // console.log(dataDetails);
    // console.log(data);
    if (data.success == "1") {
      toast.success(data.message);
      // console.log(data);
    }
    else{
      toast.error(data.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
  };
  return (
    <>
      {/* Content Header (Page header) */}
      <section className="content-header">
        <h1>Recharge History</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Recharge History</li>
        </ol>
      </section>
      {/* Main content */}
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            {/* /.box */}
            <div className="box">
              {/* /.box-header */}
              <div className="box-body">
                <div className="row " style={{ padding: "16px 0px" }}>
                  <div className="entries-per-page col-md-6 ">
                    <label htmlFor="entries">Entries per page:</label>
                    <select
                      id="entries"
                      value={entriesPerPage}
                      onChange={(e) =>
                        setEntriesPerPage(parseInt(e.target.value))
                      }
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                    </select>
                  </div>
                </div>
                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Image</th>
                      <th>To</th>
                      <th>Username</th>
                      <th>Coin</th>
                      <th>Date/Time</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(adminrechargeHistory) &&
                      adminrechargeHistory.map((item, index) => {
                        let i = index + 1;
                        return (
                          <tr>
                    
                            <td>{i}</td>
                            <td>
                              <img
                                src={`${process.env.REACT_APP_BASEURL1}${item.image}`}
                                style={{
                                  height: "70px",
                                  width: "70px",
                                  borderRadius: "50%",
                                }}
                              />
                            </td>
                            <td>{item.to == 2 ? "User" : "Coin Agency"}</td>
                            <td>{item.userId}</td>
                            <td>
                              <label htmlFor="" className="label label-success">
                                {item.coin}
                              </label>
                            </td>
                            <td>{new Date(item.createdAt).toLocaleString()}</td>
                            <td
                              className=""
                              onClick={() => {
                                const res = window.confirm(
                                  "Are you sure you want to return coin"
                                );
                                if (res == true) {
                                  const data = {
                                    id: item._id
                                  };
                                  submitHandler(data);
                                  // console.log(data);
                                }
                              }}
                            >
                              <button
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                  fontWeight: "bold",
                                  border: "none",
                                  padding: ".3rem",
                                  paddingLeft: ".5rem",
                                  paddingRight: ".5rem",
                                }}
                              >
                                Return
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className="row">
                  {/* Pagination */}

                  <div className="col-md-5">
                    <ul className="pagination">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          onClick={() => setCurrentPage(currentPage - 1)}
                          className="page-link dfsdfasdf"
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                      </li>
                      {currentPage > 2 && (
                        <li className="page-item disabled"></li>
                      )}
                      {renderPageNumbers()}
                      {currentPage < totalPages - 1 && (
                        <li className="page-item disabled"></li>
                      )}
                      <li
                        className={`page-item ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}
                      >
                        <button
                          onClick={() => setCurrentPage(currentPage + 1)}
                          className="page-link dfsdfasdf"
                          disabled={currentPage === totalPages}
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-5">
                    <div
                      className="total-entries"
                      style={{ float: "right", marginTop: "20px" }}
                    >
                      Total entries: <b>{total}</b>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.box-body */}
            </div>
            {/* /.box */}
          </div>
          {/* /.col */}
        </div>
        {/* /.row */}
      </section>
      {/* /.content */}
    </>
  );
};

export default RechargeHistory;
